<template lang="pug">
b-button-group.tab-group
  b-button.mr-2(
    v-for="(label, index) in labels"
    v-if="(customerReng && index == 5) || (index < 5)"
    :key="index"
    variant="primary"
    :class="{ off: tab !== index }"
    @click="handleTabChange(index)") {{  label }}
  .mr-2(v-if="tab == 5")
    input.form-control(type="date" placeholder="起始日期" v-model="startDate" @change="handleStartDateChange()")
  .mr-2(v-if="tab == 5")
    input.form-control(type="date" placeholder="結束日期" v-model="endDate" @change="handleEndDateChange()")
</template>

<script>
export default {
  name: 'TimePeriodTab',
  props: {
    tab: Number,
    customerReng: Boolean
  },
  data () {
    return {
      startDate: '',
      endDate: ''
    }
  },
  computed: {
    labels () {
      return [
        '今日',
        '過去7天',
        '過去30天',
        '本月',
        '上月',
        '自訂時間',
      ];
    },
  },
  methods: {
    handleTabChange (index) {
      console.log('--> set tag:', index)
      this.$emit('tab-change', index)
    },
    handleStartDateChange () {
      this.$emit('set-start-date', this.startDate)
    },
    handleEndDateChange () {
      this.$emit('set-end-date', this.endDate)
    }
  },
}
</script>

<style lang="sass" scoped>
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
</style>
