<template lang="pug">
b-modal#news-detail(
  v-model="showModal"
  :title="(showName || '') + '版位點擊詳細'", 
  title-class="font-18", 
  body-class="p-4" 
  size="lg"
)
  .row 
    .col-6.mb-2(v-for="(data, index) in content" :key="index")
      .d-flex.detail
        img(:src="data.image" :alt="data.title")
        .title {{ data.title }}
          .overlay
            .overlay-box
              .views(v-if="showName === '延伸閱讀'") {{ data.extending_click }}
              .views(v-if="showName === '相關報導'") {{ data.related_click }}
              .percentage(v-if="showName === '延伸閱讀'") {{ data.extending_percentage }}%
              .percentage(v-if="showName === '相關報導'") {{ data.related_percentage }}%
  template(#modal-footer='{ ok }')
    b-button(size='md' variant='primary' @click='ok()')
      | 關閉視窗
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    modname: {
      type: String,
    },
    content: {
      type: Array,
      default: () => { }
    }
  },
  data () {
    return {
      showModal: false,
      showName: '',
      detail: []
    }
  },
  created () {
    this.showModal = this.show
    this.showName = this.modname
  }
}

</script>
<style lang="sass" scoped>
.detail 
  padding: 8px
  background: #f5f6fa

.detail img
    width: 150px
    height: 100px
    object-fit: cover
    object-position: center

.title
  margin-left: 8px
  flex-grow: 1
  width: 100%
  height: 100px
  position: relative
    
.overlay
  position: absolute
  top: 0
  left: 0
  padding: 4px
  width: 100%
  height: 100px
  background: #222222CC
  color: #ffffff

.overlay-box
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
.views
  font-size: 30px

.percentage
  font-size: 24px
      

</style>
